import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";

import Flag from "./Flag";
import "../styles/Header.css";
import { home, products, photos, contact } from "./paths.json";
import lang from "./text/header.json";

const Header = (props) => {
  const { pathname } = useLocation();

  const text = props.language === "hu" ? lang.hu : lang.en;

  return (
    <div id={pathname === "/" ? "header-home" : "header"}>
      <Link to={home}>
        <img id="rmgLogo" alt="rmgLogo" src={"../img/logo/rmg_il.png"} />
      </Link>
      <nav id={pathname === "/" ? "navbar-home" : "navbar"}>
        <NavLink
          className="navbar-element"
          activeClassName="navbar-element-active"
          to={home}
          exact
        >
          {text.home}
        </NavLink>

        <NavLink
          className="navbar-element"
          activeClassName="navbar-element-active"
          to={products}
          exact
        >
          {text.products}
        </NavLink>

        <NavLink
          className="navbar-element"
          activeClassName="navbar-element-active"
          to={photos}
          exact
        >
          {text.photos}
        </NavLink>

        <NavLink
          className="navbar-element"
          activeClassName="navbar-element-active"
          to={contact}
          exact
        >
          {text.contact}
        </NavLink>

        <Flag
          id="flag-container"
          language={props.language}
          handleLanguageChange={props.handleLanguageChange}
        />
        <a href="https://www.instagram.com/rattila.miata/">
          <img id="ig-logo" alt="" src="/img/ig.png" />
        </a>
      </nav>
      <div className="sidebar-button-wrap">
        <MenuIcon
          fontSize="large"
          onClick={() => props.handleOpen()}
          className="sidebar-button"
        />
      </div>
    </div>
  );
};

export default Header;
