import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import emailjs from "emailjs-com";
import isEmail from "validator/es/lib/isEmail";
import { toast } from "react-toastify";
import Fade from "@material-ui/core/Fade";

import "../styles/Contact.css";
import lang from "./text/contact.json";

const Contact = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);

  const text = props.language === "hu" ? lang.hu : lang.en;

  const onSubmit = (e) => {
    e.preventDefault();

    if (isEmail(email) === false) {
      setInvalidEmail(true);
    } else {
      setInvalidEmail(false);
      emailjs
        .sendForm(
          "service_w95y80u",
          "template_9fmcbx6",
          e.target,
          "user_CIbt0xuBlvzzegZGZtNiM"
        )
        .then(
          () => {
            setName("");
            setEmail("");
            setMessage("");
            setInvalidEmail(false);
            toast.success(text.contactForm.messageSent, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          },
          (error) => {
            toast.error(text.contactForm.messageError, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            console.log(error.text);
          }
        );
    }
  };

  return (
    <Fade in={true}>
      <form id="contact-form" onSubmit={onSubmit}>
        <h2>{text.contactForm.title}</h2>
        <TextField
          required
          id="name"
          name="name"
          label={text.contactForm.name}
          variant="outlined"
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          error={invalidEmail}
          required
          id="email"
          name="email"
          label={text.contactForm.email}
          variant="outlined"
          margin="normal"
          helperText={invalidEmail ? text.contactForm.invalidEmail : ""}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          required
          id="message"
          name="message"
          label={text.contactForm.message}
          variant="outlined"
          multiline={true}
          rows="8"
          margin="normal"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button id="send">{text.contactForm.send}</button>
      </form>
    </Fade>
  );
};

export default Contact;
