import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import { options } from "../galleryOptions";
import Fade from "@material-ui/core/Fade";

import "../../styles/Product.css";
import lang from "../text/products.json";
import { contact, photos } from "../paths.json";

const SideSkirt = (props) => {
  const text = props.language === "hu" ? lang.hu : lang.en;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fade in={true}>
      <div>
        <h1 id="product-name">OEM Style Side Skirt</h1>
        <div id="product-details">
          <div>
            <h2>{text.general.details}</h2>
            <ul>
              <li>
                {text.sideSkirt.compatibility}
                <ul></ul>
              </li>
              <li>
                {text.general.specification}
                <ul>
                  <li>{text.general.material}</li>
                  <li>{text.general.gelColor}</li>
                  <li>{text.general.weight}</li>
                </ul>
              </li>
              <li>
                {text.general.installation}
                <p id="product-installation">{text.sideSkirt.installNotes}</p>
              </li>
            </ul>
          </div>
          <SRLWrapper options={options}>
            <div id="product-images">
              <a
                className="product-images-row1"
                href="..\img\products\sideskirt\fullsize\Atti_arkad00017.jpeg"
              >
                <img
                  alt=""
                  src="..\img\products\sideskirt\Atti_arkad00017t.jpg"
                />
              </a>
              <a
                className="product-images-row1"
                href="..\img\products\sideskirt\fullsize\IMG_6104.jpeg"
              >
                <img alt="" src="..\img\products\sideskirt\IMG_6104t.jpg" />
              </a>
              <a
                className="product-images-row2"
                href="..\img\products\sideskirt\fullsize\IMG_6106.JPG"
              >
                <img alt="" src="..\img\products\sideskirt\IMG_6106t.jpg" />
              </a>
              <a
                className="product-images-row2"
                href="..\img\products\sideskirt\fullsize\IMG_6107.JPG"
              >
                <img alt="" src="..\img\products\sideskirt\IMG_6107t.jpg" />
              </a>
            </div>
          </SRLWrapper>
        </div>
        <div id="product-buttons">
          <Link to={contact}>
            <button>{text.buttons.order}</button>
          </Link>
          <Link to={photos}>
            <button>{text.buttons.morePics}</button>
          </Link>
        </div>
      </div>
    </Fade>
  );
};

export default SideSkirt;
