import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import Fade from "@material-ui/core/Fade";

import lang from "./text/home.json";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/Home.css";

import { duckTail, rearLip, frontLip, sideSkirt } from "./paths.json";

const Home = (props) => {
  const text = props.language === "hu" ? lang.hu : lang.en;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fade in={true}>
      <div id="home-container">
        <Carousel
          autoPlay={true}
          interval={6000}
          infiniteLoop={true}
          useKeyboardArrows={true}
          showStatus={false}
          showThumbs={false}
        >
          <div>
            <img
              alt="ducktail"
              src="/img/products/ducktail/ducktail_slide.jpeg"
            ></img>
            <div className="carousel-overlay">
              <h1 className="carousel-label">
                <NavLink className="carousel-link" to={duckTail}>
                  Ducktail
                </NavLink>
              </h1>
            </div>
          </div>
          <div>
            <img
              alt="rearlip"
              src="/img/products/rearlip/rearlip_slide.jpeg"
            ></img>
            <div className="carousel-overlay">
              <h1 className="carousel-label">
                <NavLink className="carousel-link" to={rearLip}>
                  MSM Rear Lip
                </NavLink>
              </h1>
            </div>
          </div>
          <div>
            <img
              alt="frontlip"
              src="/img/products/frontlip/frontlip_slide.jpeg"
            ></img>
            <div className="carousel-overlay">
              <h1 className="carousel-label">
                <NavLink className="carousel-link" to={frontLip}>
                  MSM Front Lip
                </NavLink>
              </h1>
            </div>
          </div>
          <div>
            <img
              alt="sideskirt"
              src="/img/products/sideskirt/sideskirt_slide.jpeg"
            ></img>
            <div className="carousel-overlay">
              <h1 className="carousel-label">
                <NavLink className="carousel-link" to={sideSkirt}>
                  OEM Style Side Skirt
                </NavLink>
              </h1>
            </div>
          </div>
        </Carousel>
        <div id="homepage-lower">
          <div id="homepage-info">
            <img src="/img/aboutMe.JPG" alt="aboutMe" />
            <p>{text.aboutMe.content}</p>
          </div>
          <div id="products-grid">
            <div className="product-first-row">
              <Link className="product-links" to={duckTail}>
                <img
                  alt=""
                  src="/img/products/ducktail/ducktail_thumbnail.jpeg"
                />
                <div>
                  <h3>Ducktail</h3>
                </div>
              </Link>
            </div>
            <div className="product-first-row">
              <Link className="product-links" to={frontLip}>
                <img
                  alt=""
                  src="/img/products/frontlip/frontlip_thumbnail.jpeg"
                />
                <div>
                  <h3>MSM Front Lip</h3>
                </div>
              </Link>
            </div>
            <div className="product-second-row">
              <Link className="product-links" to={rearLip}>
                <img
                  alt=""
                  src="/img/products/rearlip/rearlip_thumbnail.jpeg"
                />
                <div>
                  <h3>MSM Rear Lip</h3>
                </div>
              </Link>
            </div>
            <div className="product-second-row">
              <Link className="product-links" to={sideSkirt}>
                <img
                  alt=""
                  src="/img/products/sideskirt/sideskirt_thumbnail.jpeg"
                />
                <div>
                  <h3>Side Skirt</h3>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Home;
