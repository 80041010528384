import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import { options } from "../galleryOptions";
import Fade from "@material-ui/core/Fade";

import "../../styles/Product.css";
import lang from "../text/products.json";
import { contact, photos } from "../paths.json";

const RearLip = (props) => {
  const text = props.language === "hu" ? lang.hu : lang.en;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fade in={true}>
      <div>
        <h1 id="product-name">MSM Rear Lip</h1>
        <div id="product-details">
          <div>
            <h2>{text.general.details}</h2>
            <ul>
              <li>
                {text.general.compatibility}
                <ul></ul>
              </li>
              <li>
                {text.general.specification}
                <ul>
                  <li>{text.general.material}</li>
                  <li>{text.general.gelColor}</li>
                  <li>{text.general.weight}</li>
                </ul>
              </li>
              <li>
                {text.general.installation}
                <p id="product-installation">{text.rearLip.installNotes}</p>
              </li>
            </ul>
          </div>
          <SRLWrapper options={options}>
            <div id="product-images">
              <a
                className="product-images-row1"
                href="..\img\products\rearlip\fullsize\Atti_arkad00018.jpeg"
              >
                <img alt="" src="..\img\products\rearlip\Atti_arkad00018.jpg" />
              </a>
              <a
                className="product-images-row1"
                href="..\img\products\rearlip\fullsize\IMG_6118 (1).jpeg"
              >
                <img alt="" src="..\img\products\rearlip\IMG_6118 (1)t.jpg" />
              </a>
              <a
                className="product-images-row2"
                href="..\img\products\rearlip\fullsize\IMG_5631.jpeg"
              >
                <img alt="" src="..\img\products\rearlip\IMG_5631t.jpg" />
              </a>
              <a
                className="product-images-row2"
                href="..\img\products\rearlip\fullsize\IMG_5632.jpeg"
              >
                <img alt="" src="..\img\products\rearlip\IMG_5632t.jpg" />
              </a>
            </div>
          </SRLWrapper>
        </div>
        <div id="product-buttons">
          <Link to={contact}>
            <button>{text.buttons.order}</button>
          </Link>
          <Link to={photos}>
            <button>{text.buttons.morePics}</button>
          </Link>
        </div>
      </div>
    </Fade>
  );
};

export default RearLip;
