import React, { useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Home from "./Home";
import Products from "./Products";
import Photos from "./Photos";
import Contact from "./Contact";
import NotFound from "./NotFound";
import DuckTail from "./products/DuckTail";
import SideSkirt from "./products/SideSkirt";
import FrontLip from "./products/FrontLip";
import RearLip from "./products/RearLip";

import "../styles/App.css";
import {
  home,
  products,
  photos,
  contact,
  duckTail,
  sideSkirt,
  frontLip,
  rearLip,
} from "./paths.json";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "hu"
  );

  const handleLanguageChange = (value) => {
    setLanguage(value);
    localStorage.setItem("language", value);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <BrowserRouter>
      <Header
        handleLanguageChange={handleLanguageChange}
        handleOpen={handleOpen}
        language={language}
      />

      <Sidebar
        handleLanguageChange={handleLanguageChange}
        language={language}
        handleOpen={handleOpen}
        handleClose={handleClose}
        isOpen={isOpen}
      />

      <div id="main-content">
        <Switch>
          <Route exact path={products}>
            <Products language={language} />
          </Route>
          <Route path={duckTail}>
            <DuckTail language={language} />
          </Route>
          <Route path={sideSkirt}>
            <SideSkirt language={language} />
          </Route>
          <Route path={frontLip}>
            <FrontLip language={language} />
          </Route>
          <Route path={rearLip}>
            <RearLip language={language} />
          </Route>
          <Route path={photos}>
            <Photos language={language} />
          </Route>
          <Route path={contact}>
            <Contact language={language} />
          </Route>
          <Route exact path={home}>
            <Home language={language} />
          </Route>
          <Route path="/404">
            <NotFound language={language} />
          </Route>
          <Redirect to="/404"></Redirect>
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
