import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CloseIcon from "@material-ui/icons/Close";
import { NavLink } from "react-router-dom";
import Flag from "./Flag";

import { home, products, photos, contact } from "./paths.json";
import "../styles/Sidebar.css";
import lang from "./text/header.json";

const Sidebar = (props) => {
  const text = props.language === "hu" ? lang.hu : lang.en;

  return (
    <SwipeableDrawer
      open={props.isOpen}
      onOpen={() => props.handleOpen()}
      onClose={() => props.handleClose()}
      anchor="right"
      disableSwipeToOpen={false}
    >
      <CloseIcon
        id="closeIcon"
        fontSize="large"
        onClick={() => props.handleClose()}
      />
      <div className="sidebar-content">
        <NavLink
          className="navbar-element-sidebar"
          activeClassName="navbar-element-sidebar-active"
          onClick={() => props.handleClose()}
          to={home}
          exact
        >
          {text.home}
        </NavLink>

        <NavLink
          className="navbar-element-sidebar"
          activeClassName="navbar-element-sidebar-active"
          onClick={() => props.handleClose()}
          to={products}
          exact
        >
          {text.products}
        </NavLink>

        <NavLink
          className="navbar-element-sidebar"
          activeClassName="navbar-element-sidebar-active"
          onClick={() => props.handleClose()}
          to={photos}
          exact
        >
          {text.photos}
        </NavLink>

        <NavLink
          className="navbar-element-sidebar"
          activeClassName="navbar-element-sidebar-active"
          onClick={() => props.handleClose()}
          to={contact}
          exact
        >
          {text.contact}
        </NavLink>
        <a href="https://www.instagram.com/rattila.miata/">
          <img id="ig-logo-sidebar" alt="" src="/img/ig.png" />
        </a>
        <Flag
          id="flag-container-sidebar"
          language={props.language}
          handleLanguageChange={props.handleLanguageChange}
        />
      </div>
    </SwipeableDrawer>
  );
};

export default Sidebar;
