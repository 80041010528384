import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Fade from "@material-ui/core/Fade";

import lang from "./text/products.json";
import "../styles/Products.css";
import { duckTail, frontLip, rearLip, sideSkirt } from "./paths.json";

const Products = (props) => {
  const text = props.language === "hu" ? lang.hu : lang.en;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fade in={true}>
      <div>
        <h1>{text.general.title}</h1>
        <div id="product-tiles">
          <div className="product-first-row">
            <Link className="product-links" to={duckTail}>
              <div>
                <h3>Ducktail</h3>
              </div>
              <img
                alt=""
                src="img\products\ducktail\ducktail_thumbnail_big.jpg"
              />
            </Link>
          </div>
          <div className="product-first-row">
            <Link className="product-links" to={frontLip}>
              <div>
                <h3>MSM Front Lip</h3>
              </div>
              <img
                alt=""
                src="img\products\frontlip\frontlip_thumbnail_big.jpg"
              />
            </Link>
          </div>
          <div className="product-second-row">
            <Link className="product-links" to={rearLip}>
              <div>
                <h3>MSM Rear Lip</h3>
              </div>
              <img
                alt=""
                src="img\products\rearlip\rearlip_thumbnail_big.jpg"
              />
            </Link>
          </div>
          <div className="product-second-row">
            <Link className="product-links" to={sideSkirt}>
              <div>
                <h3>Side Skirt</h3>
              </div>
              <img
                alt=""
                src="img\products\sideskirt\sideskirt_thumbnail_big.jpg"
              />
            </Link>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Products;
