import React, { useEffect } from "react";
import { SRLWrapper } from "simple-react-lightbox";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { options } from "./galleryOptions";
import Fade from "@material-ui/core/Fade";

import "../styles/Photos.css";
import lang from "./text/header.json";

const Photos = (props) => {
  const text = props.language === "hu" ? lang.hu : lang.en;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fade in={true}>
      <div>
        <h1 id="gallery-title">{text.photos}</h1>
        <SRLWrapper options={options}>
          <div id="gallery">
            <a href="img\fullsize\Atti_arkad00057.jpeg">
              <img src="img\thumbnails\Atti_arkad00057.jpeg" alt="pic" />
            </a>
            <a href="img\fullsize\cars_20200524-9.jpg">
              <img src="img\thumbnails\cars_20200524-9.jpg" alt="pic" />
            </a>
            <a href="img\fullsize\cars_20200524-52.jpg">
              <img src="img\thumbnails\cars_20200524-52.jpg" alt="pic" />
            </a>
            <a href="img\fullsize\cars_20200524-53.jpg">
              <img src="img\thumbnails\cars_20200524-53.jpg" alt="pic" />
            </a>
            <a href="img\fullsize\cars_20200524-137.jpg">
              <img src="img\thumbnails\cars_20200524-137.jpg" alt="pic" />
            </a>
            <a href="img\fullsize\cars_20200524-142.jpg">
              <img src="img\thumbnails\cars_20200524-142.jpg" alt="pic" />
            </a>
            <a href="img\fullsize\cars_20200524-143.jpg">
              <img src="img\thumbnails\cars_20200524-143.jpg" alt="pic" />
            </a>
            <a href="\img\fullsize\cirmi_osz_2020-52.jpeg">
              <img src="img\thumbnails\cirmi_osz_2020-52.jpeg" alt="pic" />
            </a>
            <a href="img\fullsize\cirmi_osz_2020-74.jpeg">
              <img src="img\thumbnails\cirmi_osz_2020-74.jpeg" alt="pic" />
            </a>
            <a href="img\fullsize\IMG_5864.jpeg">
              <img src="img\thumbnails\IMG_5864.jpeg" alt="pic" />
            </a>
            <a href="img\fullsize\IMG_5865.jpeg">
              <img src="img\thumbnails\IMG_5865.jpeg" alt="pic" />
            </a>
            <a href="img\fullsize\IMG_6106.JPG">
              <img src="img\thumbnails\IMG_6106.jpg" alt="pic" />
            </a>
            <a href="img\fullsize\IMG_6107.JPG">
              <img src="img\thumbnails\IMG_6107.jpg" alt="pic" />
            </a>
            <a href="img\fullsize\IMG_6109.JPG">
              <img src="img\thumbnails\IMG_6109.jpg" alt="pic" />
            </a>
            <a href="img\fullsize\IMG_6111.JPG">
              <img src="img\thumbnails\IMG_6111.jpg" alt="pic" />
            </a>
            <a href="img\fullsize\Photo 2020. 12. 24. 22 34 22.jpg">
              <img
                src="img\thumbnails\Photo 2020. 12. 24. 22 34 22.jpg"
                alt="pic"
              />
            </a>
            <a href="img\fullsize\Photo 2020. 12. 24. 22 35 23.jpg">
              <img
                src="img\thumbnails\Photo 2020. 12. 24. 22 35 23.jpg"
                alt="pic"
              />
            </a>
            <a href="img\fullsize\Photo 2020. 12. 24. 22 38 17.jpg">
              <img
                src="img\thumbnails\Photo 2020. 12. 24. 22 38 17.jpg"
                alt="pic"
              />
            </a>
            <a href="img\fullsize\Photo 2020. 12. 24. 22 39 11.jpg">
              <img
                src="img\thumbnails\Photo 2020. 12. 24. 22 39 11.jpg"
                alt="pic"
              />
            </a>
            <a href="img\fullsize\Photo 2020. 12. 24. 22 43 46.jpg">
              <img
                src="img\thumbnails\Photo 2020. 12. 24. 22 43 46.jpg"
                alt="pic"
              />
            </a>
            <a href="img\fullsize\punikaa_projekt_201901-8.jpeg">
              <img
                src="img\thumbnails\punikaa_projekt_201901-8.jpeg"
                alt="pic"
              />
            </a>
          </div>
        </SRLWrapper>
      </div>
    </Fade>
  );
};

export default Photos;
