import React from "react";

const Flag = (props) => {
  return (
    <div id={props.id}>
      {props.language === "hu" ? (
        <img
          src="/img/flags/hu.png"
          alt="hu"
          onClick={() => props.handleLanguageChange("en")}
        />
      ) : (
        <img
          src="/img/flags/en.png"
          alt="en"
          onClick={() => props.handleLanguageChange("hu")}
        />
      )}
    </div>
  );
};

export default Flag;
