export const options = {
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
    showThumbnailButton: false,
  },
  caption: {
    showCaption: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
};
