import React from "react";

import "../styles/NotFound.css";
import lang from "./text/notFound.json";

const NotFound = (props) => {
  const text = props.language === "hu" ? lang.hu : lang.en;

  return (
    <div id="notFound">
      <h1>404</h1>
      <p>{text.notFound}</p>
    </div>
  );
};

export default NotFound;
