import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import { options } from "../galleryOptions";
import Fade from "@material-ui/core/Fade";

import "../../styles/Product.css";
import lang from "../text/products.json";
import { contact, photos } from "../paths.json";

const FrontLip = (props) => {
  const text = props.language === "hu" ? lang.hu : lang.en;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fade in={true}>
      <div>
        <h1 id="product-name">MSM Front Lip</h1>
        <div id="product-details">
          <div>
            <h2>{text.general.details}</h2>
            <ul>
              <li>
                {text.frontLip.compatibility}
                <ul></ul>
              </li>
              <li>
                {text.general.specification}
                <ul>
                  <li>{text.general.material}</li>
                  <li>{text.general.gelColor}</li>
                  <li>{text.general.weight}</li>
                </ul>
              </li>
              <li>
                {text.general.installation}
                <p id="product-installation">{text.frontLip.installNotes}</p>
              </li>
            </ul>
          </div>
          <SRLWrapper options={options}>
            <div id="product-images">
              <a
                className="product-images-row1"
                href="..\img\products\frontlip\fullsize\Atti_arkad00034.jpeg"
              >
                <img
                  alt=""
                  src="..\img\products\frontlip\frontlip_thumbnail_big.jpg"
                />
              </a>
              <a
                className="product-images-row1"
                href="..\img\products\frontlip\fullsize\Atti_arkad00025.jpeg"
              >
                <img
                  alt=""
                  src="..\img\products\frontlip\Atti_arkad00025t.jpg"
                />
              </a>
              <a
                className="product-images-row2"
                href="..\img\products\frontlip\fullsize\IMG_5639.jpeg"
              >
                <img alt="" src="..\img\products\frontlip\IMG_5639t.jpg" />
              </a>
              <a
                className="product-images-row2"
                href="..\img\products\frontlip\fullsize\IMG_6107.jpeg"
              >
                <img alt="" src="..\img\products\frontlip\IMG_6107t.jpg" />
              </a>
            </div>
          </SRLWrapper>
        </div>
        <div id="product-buttons">
          <Link to={contact}>
            <button>{text.buttons.order}</button>
          </Link>
          <Link to={photos}>
            <button>{text.buttons.morePics}</button>
          </Link>
        </div>
      </div>
    </Fade>
  );
};

export default FrontLip;
